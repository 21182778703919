import { Link } from "@remix-run/react";
import fs from "fs";
import path from "path";
import matter from "gray-matter";
import { format } from "date-fns";
import { isDev } from "~/config";

export interface Article {
  id: string;
  title: string;
  content: string;
  date: Date;
  tags: string[];
}

let cachedArticles: Article[] | null = null;

export async function getArticles(): Promise<Article[]> {
  const articlesDirectory = path.join(process.cwd(), "app", "blog");

  if (!cachedArticles || isDev) {
    const fileNames = fs.readdirSync(articlesDirectory);
    cachedArticles = fileNames.map((fileName) => {
      const id = fileName.replace(/\.md$/, "");
      const fullPath = path.join(articlesDirectory, fileName);
      const fileContents = fs.readFileSync(fullPath, "utf8");
      const { data, content } = matter(fileContents);

      return {
        id,
        title: data.title,
        tags: data.tags?.split(",").slice(0, 3) ?? [],
        date: data.date,
        content,
      };
    });

    // Sort the articles by date in descending order (newest first)
    cachedArticles.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  return cachedArticles;
}

interface BlogArticleListProps {
  articles: Article[];
}

export const BlogArticleList: React.FC<BlogArticleListProps> = ({
  articles,
}) => {
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2">
      {articles.map((article) => (
        <article
          key={article.id}
          className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100"
        >
          <Link
            to={`/blog/${article.id}`}
            className="block hover:opacity-75 transition-opacity"
          >
            <div className="p-4">
              <p className="text-xs text-gray-400 mb-2">
                {format(new Date(article.date), "d MMM, yyyy")}
              </p>
              <h3 className="text-xl font-display text-gray-900 leading-tight">
                {article.title}
              </h3>
            </div>
          </Link>
        </article>
      ))}
    </div>
  );
};
