import {
  FormatForm,
  FormatOptionsValues,
  getFormatDefaultOptions,
} from "~/components/FormatForm";
import {
  FileSource,
  SelectFile,
  formatFromFileSource,
} from "~/components/SelectFile";
import {
  formatHasReadOptions,
  formatHasWriteOptions,
  getFormatById,
  READ_FORMATS,
  WRITE_FORMATS,
} from "~/lib/format";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { humanFileSize } from "~/lib/utils";
import { createDataLayer } from "~/config";
import { File, Run, FileReadOptions } from "~/dataLayer";
import { ChevronDownIcon } from "lucide-react";
import { Button } from "./Button";

interface ConverterProps {
  inputFormatId: string | null;
  outputFormatId: string | null;
}

function formatConversionDuration(
  startedAt: string,
  finishedAt: string
): string {
  const durationMillis =
    new Date(finishedAt).getTime() - new Date(startedAt).getTime();

  if (durationMillis < 1000) return "less than a second";

  const seconds = Math.floor(durationMillis / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (durationMillis < 60000) {
    return `${seconds} second${seconds !== 1 ? "s" : ""}`;
  } else if (durationMillis < 3600000) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  } else {
    return `${hours} hour${hours !== 1 ? "s" : ""}, ${minutes % 60} minute${
      minutes % 60 !== 1 ? "s" : ""
    }`;
  }
}

const dataLayer = createDataLayer();

export type ConversionState =
  | {
      type: "idle";
    }
  | {
      type: "converting";
    }
  | {
      type: "waiting";
      run: Run;
      cancel: () => void;
    }
  | {
      type: "finished";
      run: Run;
    };

type InputFileState =
  | {
      type: "selecting";
      fileSource: FileSource | null;
      error?: {
        type?: string;
        message: string;
      };
    }
  | {
      type: "creating";
      fileSource: FileSource;
    }
  | {
      type: "uploading";
      fileId: string;
      totalByteCount: number;
      uploadedByteCount: number;
      fileSource: Extract<FileSource, { type: "file" }>;
    }
  | {
      type: "created";
      fileSource: FileSource;
      fileId: string;
    };

async function createAndUploadFile({
  fileSource,
  readOptions,
  setState,
  formatId,
}: {
  fileSource: FileSource;
  readOptions: Record<string, unknown>;
  setState: React.Dispatch<React.SetStateAction<InputFileState>>;
  formatId: string;
}): Promise<File | null> {
  const format = getFormatById(formatId);

  if (format === null) {
    throw new Error("Unknown format");
  }

  const fullReadOptions: FileReadOptions = {
    ...getFormatDefaultOptions(format, "read"),
    ...readOptions,
    format_id: format.extends ?? format.id,
  };

  switch (fileSource.type) {
    case "file": {
      setState((s) => ({
        ...s,
        type: "creating",
        fileSource: fileSource,
      }));

      const response = await dataLayer.createFile({
        name: fileSource.file.name,
        size: fileSource.file.size,
        metadata: {},
        read_options: fullReadOptions,
      });

      if (response.error !== null) {
        setState((s) => ({
          ...s,
          type: "selecting",
          error: response.error,
        }));
        return null;
      }

      setState({
        type: "uploading",
        fileId: response.data.file_id,
        totalByteCount: fileSource.file.size,
        uploadedByteCount: 0,
        fileSource: fileSource,
      });

      const { promise } = dataLayer.uploadFile({
        fileId: response.data.file_id,
        uploadUrl: response.data.upload_url,
        file: fileSource.file,
        notifyProgress: (bytes: number) => {
          setState((s) => {
            if (s.type !== "uploading") return s;
            return {
              ...s,
              uploadedByteCount: bytes,
            };
          });
        },
      });

      await promise;

      setState({
        type: "created",
        fileSource: fileSource,
        fileId: response.data.file_id,
      });

      return response.data;
    }

    case "url": {
      const baseName = fileSource.url.split("/").pop() || "Untitled";

      setState((s) => ({
        ...s,
        type: "creating",
        fileSource: fileSource,
      }));

      const response = await dataLayer.createFile({
        name: baseName,
        url: fileSource.url,
        read_options: fullReadOptions,
      });

      if (response.error !== null) {
        setState((s) => ({
          ...s,
          type: "selecting",
          error: response.error,
        }));
        return null;
      }

      setState({
        type: "created",
        fileSource: fileSource,
        fileId: response.data.file_id,
      });

      return response.data;
    }

    case "rawText": {
      setState((s) => ({
        ...s,
        type: "creating",
        fileSource: fileSource,
      }));

      const response = await dataLayer.createFile({
        name: "Untitled",
        data: fileSource.text,
        read_options: fullReadOptions,
      });

      if (response.error !== null) {
        setState((s) => ({
          ...s,
          type: "selecting",
          error: response.error,
        }));
        return null;
      }

      setState({
        type: "created",
        fileSource: fileSource,
        fileId: response.data.file_id,
      });

      return response.data;
    }

    default:
      return null;
  }
}

export function Converter(props: ConverterProps) {
  const [inputOptions, setInputOptions] = React.useState<FormatOptionsValues>(
    {}
  );
  const [outputOptions, setOutputOptions] = React.useState<FormatOptionsValues>(
    {}
  );
  const [inputFormatId, setInputFormatId] = React.useState<string | null>(
    props.inputFormatId
  );
  const [outputFormatId, setOutputFormatId] = React.useState<string | null>(
    props.outputFormatId
  );

  const [conversionState, setConversionState] = React.useState<ConversionState>(
    { type: "idle" }
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const [isInputFormValid, setIsInputFormValid] = React.useState(false);
  const [isOutputFormValid, setIsOutputFormValid] = React.useState(false);

  const inputFormat = inputFormatId ? getFormatById(inputFormatId) : null;
  const outputFormat = outputFormatId ? getFormatById(outputFormatId) : null;

  React.useEffect(() => {
    if (props.inputFormatId !== inputFormatId) {
      setInputFormatId(props.inputFormatId);
    }
    if (props.outputFormatId !== outputFormatId) {
      setOutputFormatId(props.outputFormatId);
    }
  }, [props.inputFormatId, props.outputFormatId]);

  const [state, setState] = React.useState<InputFileState>({
    type: "selecting",
    fileSource: null,
  });

  const handleFileSourceChange = (source: FileSource) => {
    const format = formatFromFileSource(source);

    if (format && props.inputFormatId === null) {
      setInputFormatId(format.id);
    }

    setState((s) => ({
      ...s,
      type: "selecting",
      fileSource: source,
    }));
  };

  const isConverting =
    conversionState.type === "converting" ||
    conversionState.type === "waiting" ||
    state.type === "uploading" ||
    state.type === "creating";

  return (
    <>
      <div className="bg-white w-full flex flex-col border border-gray-100 rounded-lg shadow-sm">
        {(props.inputFormatId === null || props.outputFormatId === null) && (
          <div className="px-6 py-6 border-b border-gray-100">
            <div className="flex -center items-center gap-3 w-full">
              <label className="block w-full">
                <select
                  className="form-control text-lg"
                  value={inputFormatId ?? ""}
                  onChange={(e) => {
                    setInputFormatId(e.target.value);
                  }}
                >
                  <option value="" disabled defaultChecked>
                    Input format
                  </option>
                  {READ_FORMATS.map((format) => (
                    <option key={format.id} value={format.id}>
                      {format.title}
                    </option>
                  ))}
                </select>
              </label>
              <label className="">
                <ChevronRightIcon className="w-6 h-6 mt-0.5 text-primary-200" />
              </label>
              <label className="block w-full">
                <select
                  className="form-control text-lg"
                  value={outputFormatId ?? ""}
                  onChange={(e) => {
                    setOutputFormatId(e.target.value);
                  }}
                >
                  <option value="" disabled defaultChecked>
                    Output format
                  </option>
                  {WRITE_FORMATS.map((format) => (
                    <option key={format.id} value={format.id}>
                      {format.title}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        )}
        <div className="p-6 border-b border-gray-100">
          <SelectFile
            onChange={handleFileSourceChange}
            selectedSource={state.fileSource}
            enabledSources={{
              localFile: false,
              fileUpload: true,
              rawText: inputFormat?.isPlainText ?? false,
              url: true,
            }}
          />

          {"error" in state &&
            state.error &&
            state.error.type === "needs_upgrade" && (
              <div className="mx-auto overflow-auto py-3 px-4 rounded-lg bg-amber-100 border-amber-100">
                <div className="flex items-center gap-x-2.5">
                  <div>
                    <p className="text-amber-600">{state.error.message}</p>
                  </div>
                  <div className="ml-auto">
                    <a
                      href="/pricing"
                      className="text-sm btn px-3 py-2 rounded-lg bg-amber-500 hover:bg-amber-600 text-white"
                    >
                      Upgrade now
                    </a>
                  </div>
                </div>
              </div>
            )}

          {"error" in state &&
            state.error &&
            state.error.type !== "needs_upgrade" && (
              <div className="alert alert-danger">{state.error.message}</div>
            )}

          {conversionState.type === "finished" &&
            conversionState.run.status == "failed" && (
              <div className="alert alert-danger mt-4 mb-4">
                <p className="font-medium">
                  {conversionState.run.error_message}
                </p>
                <p>{conversionState.run.error_details}</p>
              </div>
            )}
        </div>
        <div className="grid grid-cols-2 mb-0 grow divide-x">
          {inputFormat && (
            <div className="w-full p-6 border-b border-slate-100">
              <div className="text-gray-400 pb-6 text-xs -mt-1 uppercase tracking-tight">
                {inputFormat.title} input options
              </div>
              <div className="flex flex-col gap-4">
                {!formatHasReadOptions(inputFormat) && (
                  <div className="text-gray-400 text-sm bg-slate-50 py-2 px-4 rounded">
                    This format does not have any input options.
                  </div>
                )}
                <FormatForm
                  format={inputFormat}
                  type="read"
                  values={inputOptions}
                  isDirty={isDirty}
                  onChange={setInputOptions}
                  onValidate={setIsInputFormValid}
                />
              </div>
            </div>
          )}
          {outputFormat && (
            <div className="w-full p-6 border-b border-slate-100 col-start-2">
              <div className="text-gray-400 pb-6 text-xs -mt-1 uppercase tracking-tight">
                {outputFormat.title} output options
              </div>
              <div className="flex flex-col gap-4">
                {!formatHasWriteOptions(outputFormat) && (
                  <div className="text-gray-400 text-sm bg-slate-50 py-2 px-4 rounded">
                    This format does not have any output options.
                  </div>
                )}
                <FormatForm
                  format={outputFormat}
                  type="write"
                  values={outputOptions}
                  isDirty={isDirty}
                  onChange={setOutputOptions}
                  onValidate={setIsOutputFormValid}
                />
              </div>
            </div>
          )}
        </div>
        <div className="px-6 pt-6 empty:hidden text-center">
          {state.type === "uploading" && (
            <div className="text-center">
              {humanFileSize(state.uploadedByteCount)} of{" "}
              {humanFileSize(state.fileSource.file.size)} uploaded
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-3 justify-items-center justify-center w-full py-6">
          <Button
            kind="primary"
            className="btn-lg"
            onClick={async () => {
              setIsDirty(true);

              if (conversionState.type === "waiting") {
                conversionState.cancel();
                setConversionState({ type: "idle" });
              }

              if (
                !inputFormatId ||
                !outputFormatId ||
                !inputFormat ||
                !outputFormat
              ) {
                setState((s) => ({
                  ...s,
                  type: "selecting",
                  error: {
                    type: "need_format",
                    message: "Please choose an input and output format",
                  },
                }));
                return;
              }

              if (!isInputFormValid || !isOutputFormValid) {
                setState((s) => ({
                  ...s,
                  type: "selecting",
                  error: {
                    type: "need_format",
                    message: "Please fix the input and output format options",
                  },
                }));
                return;
              }

              let fileId: string | null;

              if (state.type === "created") {
                fileId = state.fileId;
              } else if (state.type === "selecting" && state.fileSource) {
                const file = await createAndUploadFile({
                  fileSource: state.fileSource,
                  formatId: inputFormat.id,
                  readOptions: inputOptions,
                  setState: setState,
                });

                if (!file) {
                  return;
                }

                fileId = file.file_id;
              } else {
                setState((s) => ({
                  ...s,
                  type: "selecting",
                  error: {
                    type: "need_format",
                    message: "Please choose some data to convert",
                  },
                }));
                return;
              }

              setConversionState({
                type: "converting",
              });

              const result = await dataLayer.convertFile({
                fileId: fileId,
                input: {
                  format: inputFormatId,
                  options: inputOptions,
                },
                output: {
                  format: outputFormatId,
                  options: outputOptions,
                },
              });

              if (result.status === "error") {
                setConversionState({
                  type: "idle",
                });
                setState((s) => ({
                  ...s,
                  type: "selecting",
                  error: result.error,
                }));
                return;
              }

              setConversionState({
                type: "waiting",
                run: result.data,
                cancel: () => {},
              });

              const { promise: runPromise, cancel } = dataLayer.awaitRun({
                runId: result.data.id,
                pollEveryMs: 1000,
              });

              setConversionState({
                type: "waiting",
                run: result.data,
                cancel,
              });

              const runResult = await runPromise;

              if (runResult.status === "error") {
                return;
              }

              setConversionState({ type: "finished", run: runResult.data });
            }}
            iconLeft={
              isConverting ? <Spinner className="w-4 h-4 mr-3" /> : null
            }
          >
            {isConverting ? <span>Converting</span> : <span>Convert</span>}
          </Button>
        </div>
      </div>

      {conversionState.type === "finished" &&
        conversionState.run.output_file && (
          <>
            <div className="flex items-center py-4 justify-center">
              <ChevronDownIcon
                className="w-10 h-10 text-slate-300"
                strokeWidth={1}
              />
            </div>
            <div className="gap-x-3 p-6 border border-slate-100 bg-white rounded-lg shadow-sm">
              {conversionState.run.output_file && (
                <>
                  <div className="flex items-center gap-x-2">
                    <CheckCircleIcon className="w-4 h-4 text-green-800" />
                    <span className="text-sm text-gray-500">
                      Converted in{" "}
                      {formatConversionDuration(
                        conversionState.run.started_at,
                        conversionState.run.finished_at!
                      )}
                    </span>
                  </div>
                  {conversionState.run.output_file.size < 500 * 1000 &&
                  conversionState.run.output_file_content ? (
                    <>
                      <textarea
                        data-gramm_editor="false"
                        name="data"
                        rows={10}
                        readOnly
                        id="output"
                        wrap="off"
                        className="form-control font-mono text-sm mt-4"
                        placeholder=""
                        value={conversionState.run.output_file_content}
                      />
                      <button
                        onClick={() =>
                          navigator.clipboard.writeText(
                            conversionState.run.output_file_content!
                          )
                        }
                        className="btn btn-secondary text-md mt-4 mr-2 cursor-pointer flex items-center"
                      >
                        <svg
                          className="w-3 h-3 mr-1 inline"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                          <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                        </svg>
                        <span className="text">Copy to clipboard</span>
                      </button>
                    </>
                  ) : (
                    <div className="alert mt-4">
                      This file cannot be previewed. Please download it to view
                      it.
                    </div>
                  )}
                  <a
                    href={`/api/v1/files/${conversionState.run.output_file.file_id}/data`}
                    download
                    className="btn btn-primary text-md mt-4 flex w-fit items-center"
                  >
                    <svg
                      className="w-3 h-3 mr-2 inline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>
                      Download (
                      {humanFileSize(conversionState.run.output_file.size)})
                    </span>
                  </a>
                </>
              )}
            </div>
          </>
        )}
    </>
  );
}

const Spinner = (props: { className?: string }) => (
  <span
    className={`spinner-icon-white w-4 h-4 text-white ${props.className}`}
  ></span>
);
