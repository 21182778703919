export function renderFormatDescription(formatId: string, isInput: boolean) {
  const description = FORMAT_DESCRIPTIONS[formatId];

  if (typeof description === "function") {
    return description({
      isInput: isInput,
    });
  }

  return description || null;
}

export const FORMAT_DESCRIPTIONS: Record<
  string,
  React.ReactNode | ((props: { isInput: boolean }) => React.ReactNode)
> = {
  parquet: (
    <>
      <p>
        Apache Parquet is a columnar storage format optimized for use with big
        data processing frameworks. It offers efficient data compression and
        encoding schemes, which leads to significant storage savings and
        improved read performance.
      </p>

      <p>
        Parquet is designed to support complex nested data structures and
        enables efficient querying and manipulation of specific columns without
        reading the entire dataset.
      </p>

      <h4>Compression</h4>

      <p>
        Parquet supports various compression algorithms such as Snappy, Gzip,
        and LZO. These compression techniques help in reducing the storage space
        and improving the performance of data processing tasks.
      </p>
    </>
  ),
  csv: (
    <>
      <p>
        Comma-separated values (CSV) is a simple and widely used format for
        storing tabular data. It is human-readable and easy to generate and
        parse.
      </p>

      <h4>Row delimiter</h4>

      <p>
        The row delimiter is the character used to separate each row in the CSV
        data. This is usually a new line character (LF), or a carriage return
        plus a new line character (CRLF).
      </p>

      <p>We will automatically detect this and parse the rows correctly.</p>

      <h4>Value separator</h4>

      <p>
        The value separator is the character used to separate each value inside
        a row.
      </p>

      <p>
        For CSV files, as the name implies it is usually a comma, but it can be
        different depending on the software used to generate the CSV file, we
        support the following separators:
      </p>

      <ul>
        <li>
          Comma <code className="inline">,</code>
        </li>
        <li>
          Tab <code className="inline">\t</code>
        </li>
        <li>
          Pipe <code className="inline">|</code>
        </li>
        <li>
          Hash <code className="inline">#</code>
        </li>
        <li>
          Semicolon <code className="inline">;</code>
        </li>
      </ul>

      <h4>Character encoding</h4>

      <p>
        Depending on what software you used to generate the CSV file, it might
        have a different character encoding
      </p>

      <p>
        If no character encoding is specified we will automatically try to guess
        it, so you don&apos;t have to worry about it if you&apos;re unsure.
      </p>
    </>
  ),
  tsv: (
    <>
      <p>
        Tab-separated values (TSV) is a simple and widely used format for
        storing tabular data. It is human-readable and easy to generate and
        parse.
      </p>

      <h4>Row delimiter</h4>

      <p>
        The row delimiter is the character used to separate each row in the TSV
        data. This is usually a new line character (LF), or a carriage return
        plus a new line character (CRLF).
      </p>

      <p>We will automatically detect this and parse the rows correctly.</p>

      <h4>Value separator</h4>

      <p>
        The value separator is the character used to separate each value inside
        a row.
      </p>

      <p>
        For TSV files, as the name implies it is usually a tab character, but it
        can be different depending on the software used to generate the TSV
        file.
      </p>

      <h4>Character encoding</h4>

      <p>
        Depending on what software you used to generate the TSV file, it might
        have a different character encoding
      </p>

      <p>
        If no character encoding is specified we will automatically try to guess
        it, so you don&apos;t have to worry about it if you&apos;re unsure.
      </p>
    </>
  ),
  ndjson: (
    <>
      <p>
        NDJSON (Newline Delimited JSON) is a convenient format for storing or
        streaming structured data that may be processed one record at a time.
        Each line in an NDJSON file is a valid JSON object, separated by a
        newline character.
      </p>
      <p>
        This format is particularly useful for handling large datasets, as it
        allows for easy parsing and processing of data line by line without
        loading the entire file into memory.
      </p>
      <h4>Key Features</h4>
      <ul>
        <li>Each line is a self-contained JSON object</li>
        <li>Simple to read and write sequentially</li>
        <li>Ideal for streaming and logging applications</li>
        <li>Supports partial processing of large datasets</li>
      </ul>
      <p>
        NDJSON is commonly used in data pipelines, log processing, and scenarios
        where you need to append new data to existing files without modifying
        the entire structure.
      </p>
    </>
  ),

  arrow: (
    <>
      <p>
        Apache Arrow is a cross-language development platform for in-memory data
        that specifies a standardized language-independent columnar memory
        format for flat and hierarchical data, organized for efficient analytic
        operations on modern hardware like CPUs and GPUs.
      </p>

      <p>
        Arrow is designed to support complex nested data structures and enables
        efficient querying and manipulation of specific columns without reading
        the entire dataset.
      </p>

      <h4>Key Features</h4>

      <ul>
        <li>Columnar memory format for flat and hierarchical data</li>
        <li>Language-agnostic specification</li>
        <li>Optimized for analytical processing and modern hardware</li>
        <li>Support for complex nested data structures</li>
        <li>Efficient zero-copy reads</li>
      </ul>

      <h4>Use Cases</h4>

      <p>Apache Arrow is particularly useful in scenarios involving:</p>

      <ul>
        <li>Big data processing and analytics</li>
        <li>Machine learning and AI pipelines</li>
        <li>Data interchange between different systems and languages</li>
        <li>High-performance computing applications</li>
      </ul>

      <p>
        Its efficient memory layout and standardized format make it an excellent
        choice for applications requiring fast data processing and
        interoperability between different tools and languages.
      </p>
    </>
  ),

  json: (
    <>
      <p>
        JSON (JavaScript Object Notation) is a lightweight data interchange
        format that is easy for humans to read and write and easy for machines
        to parse and generate.
      </p>

      <p>
        Originally created for JavaScript, JSON quickly has become the dominant
        format for web data exchange due to its simplicity and versatility. It's
        now widely used across various programming languages and platforms.
      </p>

      <p>
        Primarily used in web applications, JSON efficiently represents
        structured data from simple key-value pairs to complex nested objects
        and arrays, making it ideal for client-server communication.
      </p>

      <h4>Schema</h4>

      <p>
        JSON's flexible structure, while advantageous, can pose challenges for
        data integrity and processing, especially with large or complex
        datasets.
      </p>
      <p>
        Konbert automatically detects the schema of your JSON data. By scanning
        your JSON file to identify its structure, we ensure all data is
        accurately captured and maintained throughout the conversion process,
        preserving the integrity of your information.
      </p>

      <h4>Character encoding</h4>

      <p>
        As stated by the RFC 8259, we expect strings in uploaded JSON files to
        be encoded in UTF-8.
      </p>

      <p>
        If your file contains non UTF-8 characters, you might get an error when
        parsing your data.
      </p>

      <h4>Nested data</h4>

      <p>
        We support reading and flattening nested objects and arrays in case you
        want to convert it to flat data like CSV or Excel.
      </p>
    </>
  ),
  avro: (
    <>
      <p>
        Apache Avro is a row-oriented remote procedure call and data
        serialization framework developed within Apache&apos;s Hadoop project.
        It uses JSON for defining data types and protocols, and serializes data
        in a compact binary format.
      </p>

      <p>
        Avro is designed to support complex nested data structures and enables
        efficient querying and manipulation of specific columns without reading
        the entire dataset.
      </p>
    </>
  ),
  xls: (
    <>
      <p>
        XLS files are Excel files that use the Binary Interchange File Format
        (BIFF), which was the default format for Excel versions 97-2003.
      </p>

      <p>
        XLS files can contain multiple sheets, and each sheet can contain
        multiple rows and columns of data. They support various data types,
        including text, numbers, dates, and formulas.
      </p>

      <p>Key features of XLS files include:</p>
      <ul>
        <li>
          Compatibility with older versions of Excel and other spreadsheet
          software
        </li>
        <li>Ability to store formatting information, charts, and macros</li>
        <li>Smaller file size compared to newer formats like XLSX</li>
        <li>Limited to 65,536 rows and 256 columns per sheet</li>
      </ul>

      <p>
        While XLS files are still widely used, they are gradually being replaced
        by the more modern XLSX format.
      </p>
    </>
  ),
  xlsx: (
    <>
      <p>
        XLSX files are Excel files that use the Office Open XML format,
        introduced with Microsoft Excel 2007.
      </p>

      <p>
        XLSX files can contain multiple sheets, and each sheet can contain
        multiple rows and columns of data. They offer several advantages over
        the older XLS format.
      </p>

      <p>Key features of XLSX files include:</p>
      <ul>
        <li>Improved compression, resulting in smaller file sizes</li>
        <li>
          Enhanced security features, including password protection and digital
          signatures
        </li>
        <li>Better recovery from file corruption</li>
        <li>
          Support for more rows (1,048,576) and columns (16,384) per sheet
        </li>
        <li>
          Compatibility with a wide range of applications and programming
          languages
        </li>
      </ul>

      <p>
        XLSX files are based on XML and ZIP technologies, making them more
        accessible for third-party software to read and write.
      </p>
    </>
  ),
  excel: (
    <>
      <p>
        Microsoft Excel is a powerful spreadsheet application developed by
        Microsoft for Windows, macOS, Android, and iOS. It features calculation
        capabilities, graphing tools, pivot tables, and a macro programming
        language called Visual Basic for Applications (VBA).
      </p>

      <p>
        Excel files can contain multiple sheets, and each sheet can contain
        multiple rows and columns of data. Excel supports various file formats,
        including XLS, XLSX, and CSV.
      </p>

      <p>Key features of Microsoft Excel include:</p>
      <ul>
        <li>
          Data analysis tools like sorting, filtering, and conditional
          formatting
        </li>
        <li>Ability to create complex formulas and use built-in functions</li>
        <li>Charting and graphing capabilities for data visualization</li>
        <li>Pivot tables for summarizing and analyzing large datasets</li>
        <li>Macro recording and VBA programming for automation</li>
        <li>
          Collaboration features, including real-time co-authoring in newer
          versions
        </li>
      </ul>

      <p>
        Excel is widely used in business, finance, and data analysis for tasks
        ranging from simple calculations to complex modeling and reporting.
      </p>
    </>
  ),
  ods: (
    <>
      <p>
        OpenDocument Spreadsheet (ODS) is an open standard file format for
        spreadsheets that is part of the OpenDocument Format (ODF) family. It
        was developed by the Organization for the Advancement of Structured
        Information Standards (OASIS) and is widely supported by various office
        suites, including LibreOffice and Apache OpenOffice.
      </p>

      <p>
        ODS files can contain multiple sheets, and each sheet can contain
        multiple rows and columns of data. The format supports a wide range of
        features including formulas, styles, charts, and macros.
      </p>

      <p>
        One of the key advantages of ODS is its open nature, which ensures
        long-term accessibility and interoperability. It uses a compressed XML
        format, making it both efficient in storage and easy to process
        programmatically.
      </p>
    </>
  ),
  mysql: (
    <>
      <p>
        MySQL is an open-source relational database management system (RDBMS)
        that uses Structured Query Language (SQL).
      </p>
      <p>
        It's widely used for web applications and is known for its reliability,
        performance, and ease of use. MySQL supports multiple storage engines,
        transactions, and replication.
      </p>
    </>
  ),
  postgres: (
    <>
      <p>
        PostgreSQL, often referred to as Postgres, is a powerful, open-source
        object-relational database system.
      </p>
      <p>
        It's known for its robustness, extensibility, and standards compliance.
        PostgreSQL offers advanced features like complex queries, foreign keys,
        triggers, and transactional integrity.
      </p>
    </>
  ),
  sqlite: (
    <>
      <p>
        SQLite is a C-language library that implements a small, fast,
        self-contained, high-reliability, full-featured, SQL database engine.
      </p>
      <p>
        It's the most used database engine in the world, embedded in many
        applications. SQLite is serverless and requires zero configuration,
        making it ideal for local/client storage in application software.
      </p>
    </>
  ),
  sql: (
    <>
      <p>
        SQL (Structured Query Language) is a standard language for storing,
        manipulating, and retrieving data in relational database management
        systems.
      </p>
      <p>
        It's used by many database systems including MySQL, PostgreSQL, SQLite,
        and others. SQL allows you to create, read, update, and delete database
        records with commands like SELECT, INSERT, UPDATE, and DELETE.
      </p>
    </>
  ),
  "avro-schema": (
    <>
      <p>
        An Avro schema is a JSON document that defines the structure of Avro
        data.
      </p>
      <p>
        It specifies the fields, their names, and data types for Avro data,
        enabling schema evolution and ensuring data consistency.
      </p>
    </>
  ),
  jsonl: (
    <>
      <p>
        JSON Lines, also known as JSONL, is a convenient format for storing
        structured data that may be processed one record at a time.
      </p>
      <p>
        Each line in a JSON Lines file is a valid JSON object, typically
        representing a single record. The lines are separated by newline
        characters, making it easy to read and write sequentially.
      </p>
      <p>
        This format is particularly useful for logging, streaming, and other
        scenarios where you want to process data line by line without having to
        parse an entire JSON array.
      </p>
    </>
  ),
  markdown: (
    <>
      <p>
        Markdown is a lightweight markup language with plain-text formatting
        syntax. It's designed to be easy to read, write, and convert to HTML and
        other formats.
      </p>
      <p>Key features of Markdown include:</p>
      <ul>
        <li>Simple syntax for headings, lists, links, and emphasis</li>
        <li>Support for inline HTML</li>
        <li>Easy to learn and use, even for non-technical users</li>
        <li>Widely supported across various platforms and applications</li>
      </ul>
      <p>Markdown is commonly used for:</p>
      <ul>
        <li>Documentation and README files</li>
        <li>Forum and blog posts</li>
        <li>Note-taking and writing</li>
        <li>Creating content for static site generators</li>
      </ul>
      <p>
        While the core syntax is standardized, there are several flavors of
        Markdown with extended features, such as GitHub Flavored Markdown (GFM)
        and CommonMark.
      </p>
    </>
  ),
  pdf: (
    <>
      <p>
        PDF (Portable Document Format) is a file format developed by Adobe to
        present documents consistently across different devices and platforms.
      </p>
      <p>Key features of PDF include:</p>
      <ul>
        <li>
          Preserves document formatting, including fonts, images, and layout
        </li>
        <li>Supports interactive elements like forms and hyperlinks</li>
        <li>Can be password-protected and encrypted for security</li>
        <li>
          Widely supported and viewable on various devices and operating systems
        </li>
      </ul>
      <p>PDFs are commonly used for:</p>
      <ul>
        <li>Sharing official documents and reports</li>
        <li>Creating printable materials like brochures and flyers</li>
        <li>Distributing e-books and digital publications</li>
        <li>Archiving documents for long-term storage</li>
      </ul>
      <p>
        While PDFs are excellent for preserving document appearance, they can be
        less flexible for editing compared to other formats.
      </p>
    </>
  ),
  txt: (
    <>
      <p>
        TXT (Text) is a simple, plain text file format that contains unformatted
        text.
      </p>
      <p>Key features of TXT files include:</p>
      <ul>
        <li>
          Universal compatibility across all operating systems and devices
        </li>
        <li>Lightweight and easy to create, edit, and read</li>
        <li>No formatting or styling, just pure text content</li>
        <li>Can be opened and edited with any text editor</li>
      </ul>
      <p>TXT files are commonly used for:</p>
      <ul>
        <li>Simple note-taking and quick memos</li>
        <li>Creating readme files for software projects</li>
        <li>Storing configuration information</li>
        <li>Exchanging raw data between different systems</li>
      </ul>
      <p>
        While TXT files lack advanced formatting options, their simplicity makes
        them ideal for situations where plain text is sufficient and file size
        needs to be minimal.
      </p>
    </>
  ),
};
