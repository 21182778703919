import {
  Format,
  WRITE_FORMATS,
  formatSupportsRead,
  formatSupportsWrite,
  getFormatById,
} from "~/lib/format";
import { MetaFunction, json } from "@remix-run/node";
import { Article, BlogArticleList, getArticles } from "~/lib/blog";
import { useLoaderData, Link } from "@remix-run/react";
import FileConvertersGrid from "~/components/ConvertersGrid";
import { renderFormatDescription } from "~/components/FormatDescriptions";
import Layout from "~/components/Layout";
import { Converter } from "~/components/Converter";

interface LoaderData {
  pageTitle: string;
  pageDescription: string;
  h1: string;
  h2: string;
  relatedArticles: Article[];
  from: Format | null;
  to: Format | null;
  fileId: string | null;
}

export const loader = async ({
  params,
  request,
}: {
  params: { from?: string; to?: string };
  request: Request;
}) => {
  const { from, to } = params;

  if (!from || !to) {
    return json({
      pageTitle: "Convert Data Files and Databases Online",
      pageDescription:
        "Fast and modern data converter. Convert between large or small CSV, JSON, SQL, Excel files in seconds.",
      h1: "Data Converter",
      h2: "Convert tabular files, structured data and databases online.",
      from: null,
      to: null,
      relatedArticles: [],
      fileId: null,
    } as LoaderData);
  }

  const fromFormat = getFormatById(from);
  const toFormat = getFormatById(to);

  if (
    !fromFormat ||
    !toFormat ||
    !formatSupportsRead(fromFormat) ||
    !formatSupportsWrite(toFormat)
  ) {
    throw new Response("Not Found", { status: 404 });
  }

  const metaTitle = `${fromFormat.metaTitle || fromFormat.title} to ${
    toFormat.metaTitle || toFormat.title
  } Converter`;

  const extraFeatures = [
    ...new Set(
      [fromFormat.extraFeatures, toFormat.extraFeatures].filter(Boolean)
    ),
  ]
    .join(" ")
    .trim();

  const metaDescription = `Free ${
    fromFormat.longTitle || fromFormat.title
  } to ${
    toFormat.longTitle || toFormat.title
  } converter online, for small or large files.${
    extraFeatures ? ` ${extraFeatures}` : ""
  } Upload and convert. No sign up required.`;

  const h2 = `Transform your ${fromFormat.title} data to ${toFormat.title} effortlessly. Just upload your data and let us do the rest.`;

  const h1 = `Convert ${fromFormat.title} to ${toFormat.title}`;

  const allArticles = await getArticles();
  const relatedArticles = allArticles
    .filter(
      (article) =>
        article.tags.includes(fromFormat.id) ||
        article.tags.includes(toFormat.id)
    )
    .slice(0, 4);

  const url = new URL(request.url);
  const fileId = url.searchParams.get("file_id");

  return json({
    pageTitle: metaTitle,
    pageDescription: metaDescription,
    h1,
    h2,
    relatedArticles,
    from: fromFormat,
    to: toFormat,
    fileId,
  });
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) {
    return [];
  }

  return [
    { title: data.pageTitle },
    { name: "description", content: data.pageDescription },
  ];
};

export default function ConvertPage() {
  const { h1, h2, relatedArticles, from, to } = useLoaderData<typeof loader>();

  return (
    <Layout className="bg-gray-50">
      <div className="mx-auto px-4 w-full max-w-screen-lg flex flex-col py-8 pb-28">
        <div className="text-center max-w-xl mx-auto pb-8">
          <div className="py-12">
            <h1 className="text-4xl lg:text-5xl font-bold font-display tracking-tight mb-4 text-black">
              {h1}
            </h1>
            <h2 className="text-slate-500 text-lg">{h2}</h2>
          </div>
        </div>
        <div className="max-w-screen-md mx-auto w-full">
          <Converter
            inputFormatId={from?.id ?? null}
            outputFormatId={to?.id ?? null}
          />
        </div>
      </div>

      {from && to && (
        <div className="bg-white px-6">
          <div className="max-w-screen-lg mx-auto w-full flex flex-col md:flex-row gap-x-16 text-primary-900/70">
            {[
              { type: "input", format: from },
              { type: "output", format: to },
            ].map(({ type, format }) => (
              <article key={type} className="my-8 md:my-16">
                <h3 className="font-display text-2xl font-semibold pb-6 text-primary-900">
                  {format.title}
                </h3>
                <div className="prose">
                  {renderFormatDescription(format.id, type === "input")}
                </div>
              </article>
            ))}
          </div>
        </div>
      )}
      <section className="py-16 bg-gray-50 px-4">
        {from ? (
          <>
            <h3 className="text-2xl lg:text-3xl font-bold font-display tracking-tight text-primary-800 mb-4 text-center pb-16">
              Convert {from.title}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-screen-lg mx-auto">
              {WRITE_FORMATS.filter((output) => output.id !== from.id).map(
                (output) => (
                  <Link
                    key={output.id}
                    to={`/convert/${from.id}/to/${output.id}`}
                    title={`Convert ${from.title} to ${output.title}`}
                    className="flex bg-white dark:bg-gray-950 rounded-lg shadow-sm border border-gray-200 hover:border-gray-300 dark:border-gray-800 overflow-hidden py-2 px-3"
                  >
                    {from.title} to {output.title}
                  </Link>
                )
              )}
            </div>
          </>
        ) : (
          <FileConvertersGrid title="Data Converters" />
        )}
      </section>
      {relatedArticles?.length > 0 && (
        <section className="py-24 px-4 bg-white">
          <div className="max-w-screen-lg mx-auto w-full">
            <h3 className="font-display text-3xl font-semibold text-primary-900 text-center pb-16">
              Related articles
            </h3>
            <BlogArticleList
              articles={relatedArticles.map((article) => ({
                ...article,
                date: new Date(article.date),
              }))}
            />
          </div>
        </section>
      )}
    </Layout>
  );
}
